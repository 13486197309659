import React from "react";
import './Normy.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Row, Container} from "react-bootstrap";

function Normy() {
    return (
        <Container>
            <Row className="justify-content-sm-center top">
                <img className="logo" src="/img/normy/logo.png" alt="logo"/>
            </Row>
            <Row className="justify-content-sm-center top-text">
                <>Connecting the world through surveys</>
            </Row>
            <Row>
                <div className="col-sm">
                    <img className="img" src="/img/normy/newpoll.png" alt="newpoll"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/normy/postquestion.png" alt="postquestion"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/normy/question.png" alt="question"/>
                </div>
            </Row>
            <Row className="bottom-row">
                <div className="col-sm">
                    <img className="img" src="/img/normy/signup.png" alt="signup"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/normy/leaderboard.png" alt="leaderboard"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/normy/activepolls.png" alt="activepolls"/>
                </div>
            </Row>
        </Container>
    )
}

export default Normy;