import React from "react";
import './Hownd.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Row, Container} from "react-bootstrap";

function Hownd() {
    return (
        <Container>
            <Row className="justify-content-sm-center top">
                <a className="logo-image center" href="https://www.hownd.com/">
                    <img className="logo-image center" src="/img/hownd/logo.png" alt="logo"/>
                </a>
            </Row>
            <Row className="justify-content-sm-center top">
                <>Generating effortless foot traffic for local business owners worldwide.</>
            </Row>
            <Row className="rows">
                <div className="col-sm">
                    <img className="img" src="/img/hownd/preview.png" alt="preview"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/preview_coupon.png" alt="preview_coupon"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/redeem.png" alt="redeem"/>
                </div>
            </Row>
            <Row className="rows">
                <div className="col-sm">
                    <img className="img" src="/img/hownd/signup_step1.png" alt="signup_step1"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/signup_step2.png" alt="signup_step2"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/active_tile.png" alt="active_tile"/>
                </div>
            </Row>
            <Row className="rows">
                <div className="col-sm">
                    <img className="img" src="/img/hownd/faq.png" alt="faq"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/inactive_membership.png" alt="inactive_membership"/>
                </div>
                <div className="col-sm">
                    <img className="img" src="/img/hownd/post_signup.png" alt="post_signup"/>
                </div>
            </Row>
        </Container>
    )
}

export default Hownd;
