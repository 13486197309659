import React, { useState, useEffect } from 'react';
import './sectionopposite.css';

function SectionOpposite(props) {
    const [isMobile, setIsMobile] = useState(true)
 
    //choose the screen size 
    const handleResize = () => {
        if (window.innerWidth < 550) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }

    // create an event listener
    useEffect(() => {
    window.addEventListener("resize", handleResize)
    })

    return (
        <div className="section_container">
        {isMobile ? (
            <>
                <div className="section_text">
                    <div className="section_title">
                        <h4>{props.title}</h4>
                    </div>
                    <div className="section_body">
                        <p>{props.text}</p>
                    </div>
                </div>
                <div className="section_image">
                    <a href={props.link} target="_blank" rel="noreferrer">
                        <img alt={props.title} className="section__image" src={props.img}/>
                    </a>
                </div>
            </>
        ) : (
            <>
                <div className="section_image">
                    <a href={props.link} target="_blank" rel="noreferrer">
                        <img alt={props.title} className="section__image" src={props.img}/>
                    </a>
                </div>
                <div className="section_text">
                    <div className="section_title">
                        <h4>{props.title}</h4>
                    </div>
                    <div className="section_body">
                        <p>{props.text}</p>
                    </div>
                </div>
            </>
        )
        }
        </div>
    );
}

export default SectionOpposite;