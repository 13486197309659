import React from "react";
import {Navbar, Nav, Container} from 'react-bootstrap';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import logo from "./logo.png";

function Header() {
    return (
        <Navbar>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt="logo"
                        src={logo}
                        width="100"
                        height="100"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto nav-components">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/experiences">Experiences</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;