import React from 'react';
import './section.css';

function Section(props) {
    return (
        <div className="section_container">
            <div className="section_image">
                <a href={props.link} target="_blank" rel="noreferrer">
                    <img alt={props.title} className="section__image" src={props.img}/>
                </a>
            </div>
            <div className="section_text">
                <div className="section_title">
                    <h4>{props.title}</h4>
                </div>
                <div className="section_body">
                    <p>{props.text}</p>
                </div>
            </div>
        </div>
    );
}

export default Section;