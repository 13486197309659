import {Image, Row} from 'react-bootstrap';
import './Footer.css';
import github from "./github.png";
import linkedin from "./linkedin.png";
import mail from "./mail.png";
import 'bootstrap/dist/css/bootstrap.min.css'

function Footer() {
    return (
        // <div className="footer">
        //     <div class="container mt-4">
        //         <div class="row">
        //             <div class="col-md-6 p-3" align="center">
        //                 <p>Copyright {new Date().getFullYear()}, Built by Adyant Khanna</p>
        //             </div>
        //             <div class="col-md-6 pl-6" align="center">
        //                 <a href="mailto:adyantk@berkeley.edu">
        //                     <Image
        //                         src={mail}
        //                         alt="yes"
        //                         className="photo-mail"
        //                     />
        //                 </a>
        //                 <a href="https://www.linkedin.com/in/adyantkhanna">
        //                     <Image
        //                         src={linkedin}
        //                         alt="yes"
        //                         className="photo-linkedin"
        //                     />
        //                 </a>
        //                 <a href="https://github.com/adyantkhanna">
        //                     <Image
        //                         src={github}
        //                         alt="yes"
        //                         className="photo-github"
        //                     />
        //                 </a>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="footer-overall">
            <Row>
                <div align="center">
                    <p>Copyright {new Date().getFullYear()}, Built by Adyant Khanna</p>
                </div>
            </Row>
            <Row>
                <div align="center">
                    <a href="mailto:adyantk@berkeley.edu">
                        <Image
                            src={mail}
                            alt="yes"
                            className="photo-mail"
                        />
                    </a>
                    <a href="https://www.linkedin.com/in/adyantkhanna">
                        <Image
                            src={linkedin}
                            alt="yes"
                            className="photo-linkedin"
                        />
                    </a>
                    <a href="https://github.com/adyantkhanna">
                        <Image
                            src={github}
                            alt="yes"
                            className="photo-github"
                        />
                    </a>
                </div>
            </Row>
        </div>
    )
}

export default Footer;