import React from "react";
import './App.css';
import Header from "./components/Navbar/Navbar";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from "./pages/Home/Home"
import Experiences from "./pages/Experiences/Experiences";
import Normy from "./pages/Experiences/Normy/Normy";
import Hownd from "./pages/Experiences/Hownd/Hownd";
import Footer from "./components/Footer/Footer";

function App() {
    return (
        <Router>
            <div className="App">
                <Header/>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/experiences" component={Experiences}/>
                    <Route path="/normy" component={Normy} />
                    <Route path="/hownd" component={Hownd} />
                </Switch>
                <Footer />
            </div>
        </Router>
    )
}

export default App;
