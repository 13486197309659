import React from "react";
import './Home.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import image from "../adyant.jpg";

const text = [
    {
        header: <>Welcome!</>,
        body: <>My name is Adyant. I am a fourth year student
            at UC Berkeley studying Computer Science and Business Administration.</>,
    }
]

function Home() {
    return (
        <div class="container">
            <div class="row">
                <div class="centered col-md-6" align="center">
                    <img className="img" src={image} alt="adyant"/>
                </div>
                <div class="col-md-6" align="center">
                    <div class="centered container">
                        <div class="col">
                            <div class="row-md-3"><h4>{text[0].header}</h4></div>
                            <div class="row-md-3 mt-4"><p>{text[0].body}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;