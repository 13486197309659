import React from 'react';
import './Experiences.css';
import Section from '../../components/Section/Section';
import SectionOpposite from '../../components/SectionOpposite/SectionOpposite';

function Experiences() {
    return (
        <>
            <Section img="/img/roblox.jpg" link="https://www.roblox.com/" text="Building products to allow creators to develop engaging experiences anywhere." title="Product Management Intern @ Roblox"/>
            
            <SectionOpposite img="/img/hownd-logo.png" link="/hownd" text="A software company with the mission to help local businesses generate local foot traffic by delivering targeted promotions." title="Software Engineering @ Hownd"/>

            <Section img="/img/bellator-trust.jpg" link="https://www.bellatortrust.com/" text="Search Fund looking to buy an established SaaS business to grow across a niche tech vertical." title="Analyst @ Bellator Trust"/>

            <SectionOpposite img="/img/uber-logo.jpg" link="https://www.uber.com/" text="Worked on product strategy for Uber's ride-sharing and Uber Eats platforms, understanding customer and driver needs." title="Consultant @ Uber"/>

            <Section img="/img/normy-logo.png" link="/normy" text="Designed and building a consumer facing social media app allowing users to interact through survey questions and answers." title="Co-Founder @ Normy"/>

            <SectionOpposite img="/img/sustainable-logo.jpg" link="/experiences" text="Nonprofit organization I founded working to empower impoverished Indian communities in central India through water infrastructure." title="Founder @ Sustainable Water Project"/>
        </>
    );
}

export default Experiences;
